.theme-purple {
  --main-950: #480054;
  --main-900: #6D137C;
  --main-800: #831098;
  --main-700: #A111BF;
  --main-600: #B919E0;
  --main-500: #D339FC;
  --main-400: #E36FFF;
  --main-300: #ECA5FF;
  --main-200: #F3CCFF;
  --main-100: #F9E6FF;
  --main-50: #FDF3FF;
}
